var baseURL = "https://dev-api.movemates.de";

if (process.env.REACT_APP_IS_STAGE === "true") {
  baseURL = "https://staging-api.movemates.de";
} else if (process.env.NODE_ENV === "production") {
  baseURL = "https://api.movemates.de";
}

export function fetchOrder(id) {
  const url = `${baseURL}/orders/orders/${id}`;
  return fetch(url).then((res) => {
    if (res.ok) return res.json();
    throw new Error("Network response error.");
  });
}

export function fetchDriver(id) {
  const url = `${baseURL}/orders/drivers/${id}`;
  return fetch(url).then((res) => {
    if (res.ok) return res.json();
    throw new Error("Network response error.");
  });
}

export function fetchLivetracking(id) {
  const url = `${baseURL}/orders/orders/${id}/livetrack`;
  return fetch(url).then((res) => {
    if (res.ok) return res.json();
    throw new Error("Network response error.");
  });
}
