import React, { Component } from "react";
import { fetchOrder } from "../../lib/api";
import MapContainer from "../MapContainer/MapContainer";
import DriverDetailsOverlay from "../DriverDetailsOverlay/DriverDetailsOverlay";

class RootContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      order: null
    };

    this.fetchOrder = this.fetchOrder.bind(this);
  }
  componentDidMount() {
    if (this.state.id && !this.state.order) this.fetchOrder(this.state.id);
  }
  render() {
    return this.state.order ? (
      <div>
        <MapContainer order={this.state.order} />
        <DriverDetailsOverlay order={this.state.order} />
      </div>
    ) : (
      <div>Loading Order...</div>
    );
  }
  fetchOrder(id) {
    fetchOrder(id)
      .then(order => {
        this.setState({ order });
      })
      .catch(console.error);
  }
}

export default RootContainer;
