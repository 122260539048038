import React, { Component } from "react";
import { fetchDriver } from "../../lib/api";
import { Container, Row, Col, Collapse, Button } from "reactstrap";
import * as Icon from 'react-feather';
import classNames from 'classnames';
import truck from '../../artwork/truck.svg';
import "./DriverDetailsOverlay.css"

class DriverDetailsOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            driver: null,
            isExpanded: false
        }

        this.fetchDriver = this.fetchDriver.bind(this)
        this.toggleCollapse = this.toggleCollapse.bind(this)
    }
  render() {
      return this.state.driver ? (
        <div className="driver-details-overlay">
        <div><img alt="truck" className="truck-icon" src={truck} /></div>
        <Container fluid={true}>
            <Row className="clickable align-items-center justify-content-between" onClick={this.toggleCollapse} >
            <div className="spacer" />

                <Col xs="auto">
                <h2>{this.state.driver.name}</h2>
                <h6>Auftrag Nummer {this.props.order.order_number}</h6>
                </Col>
                <Col xs="auto">
                <Icon.ChevronUp size={48} className={classNames({"expand-icon": true, "expanded": this.state.isExpanded})} />
                </Col>
                <div className="spacer" />
            </Row>
            <Collapse isOpen={this.state.isExpanded}>
            <Row className="align-items-center">
                <Col xs="auto">
                <Icon.MapPin size={36} color="#8BE9A9" />
                </Col>
                <Col xs="auto">
                <h5>{this.props.order.from.street} {this.props.order.from.houseNumber}</h5>
                <h6>{this.props.order.from.postalCode} {this.props.order.from.city}</h6>
                </Col>
            </Row>
            <Row className="align-items-center">
            <div className="spacer" />
                <Col xs="auto">
                <Icon.MapPin size={36} color="#FA8772" />
                </Col>
                <Col xs="auto">
                <h5>{this.props.order.to.street} {this.props.order.to.houseNumber}</h5>
                <h6>{this.props.order.to.postalCode} {this.props.order.to.city}</h6>
                </Col>
            </Row>
            <Row>
            <div className="spacer" />

                <Col xs="6">
                <Button color="success" className="filling-button" href={`tel:${this.state.driver.phone}`}>Fahrer anrufen</Button>
                </Col>
                <Col xs="6">
                <Button color="primary" className="filling-button" href={`sms:${this.state.driver.phone}`}>Nachricht senden</Button>
                </Col>
                <div className="spacer" />

            </Row>
            </Collapse>
        </Container>
      </div>
      ) : (
          <div>Lade Fahrer...</div>
      )
  }
  componentDidMount() {
      this.fetchDriver(this.props.order.driver)
  }
  fetchDriver(id) {
      fetchDriver(id)
      .then(driver => {
          this.setState({driver})
      })
      .catch(console.log)
  }
  toggleCollapse() {
      this.setState({isExpanded: !this.state.isExpanded})
  }
}

export default DriverDetailsOverlay;