import React, { Component } from "react";
import { fetchLivetracking } from "../../lib/api";
import { Map, Marker } from "react-leaflet";
import MapboxGlLayer from "./MapBoxGlLayer";
import leaflet from "leaflet";
import logo from "../../artwork/logo.svg";
import "./MapContainer.css";

const driverIcon = leaflet.divIcon({
  className: "css-icon",
  html: '<div class="inner-ring"></div><div class="gps-ring"></div>',
  iconSize: [22, 22],
});

const fromIcon = leaflet.divIcon({
  className: "pin-icon",
  html: '<img alt="green pin" src="/pin-green.svg" />',
  iconSize: [22, 22],
});

const toIcon = leaflet.divIcon({
  className: "pin-icon",
  html: '<img alt="red pin" src="/pin-red.svg" />',
  iconSize: [22, 22],
});

class MapContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tracking: null,
      updateCount: 0,
      isInitialRender: true,
    };

    this.fetchTracking = this.fetchTracking.bind(this);
  }
  componentDidMount() {
    this.fetchTracking(this.props.order.id);
    this.interval = setInterval(
      () => this.fetchTracking(this.props.order.id),
      3000
    );
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    if (this.state.tracking) {
      const driverPosition = [this.state.tracking.lat, this.state.tracking.lon];
      const markers = [
        driverPosition,
        this.props.order.from.location,
        this.props.order.to.location,
      ].map(leaflet.marker);
      const features = new leaflet.featureGroup(markers);
      return (
        <div>
          <img alt="movemates logo" className="logo" src={logo} />
          <Map
            bounds={this.state.isInitialRender ? features.getBounds() : null}
            boundsOptions={{
              paddingBottomRight: [0, 170],
              paddingTopLeft: [0, 100],
            }}
            zoomControl={false}
            useFlyTo={true}
            animate={true}
          >
            <MapboxGlLayer
              accessToken="pk.eyJ1IjoibW92ZW1hdGVzIiwiYSI6ImNqZDRyMHVqajQ3N2syd281bGp6MGMweDgifQ._vjsWevieI74HlVZIThHrg"
              style="mapbox://styles/mapbox/light-v10"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={this.props.order.from.location} icon={fromIcon} />
            <Marker position={this.props.order.to.location} icon={toIcon} />
            <Marker position={driverPosition} icon={driverIcon} />
          </Map>
        </div>
      );
    } else {
      return <div>Starte Tracking...</div>;
    }
  }
  componentDidUpdate() {
    if (this.state.tracking && this.state.isInitialRender)
      this.setState({ isInitialRender: false });
  }
  fetchTracking(id) {
    fetchLivetracking(id)
      .then((tracking) => {
        this.setState({
          tracking,
          updateCount: this.state.updateCount + 1,
        });
      })
      .catch(console.error);
  }
}

export default MapContainer;
